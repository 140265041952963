<template>
  <FullLayout>
    <div class="h-full overflow-hidden relative viewport w-full">
      <google-map-loader
        :apiKey="apiKey"
        :mapConfig="mapOptions"
        @drag="updateLocation"
        @removeOldMarkers="remove++"
      >
        <template slot-scope="{ google, map }">
          <project-map-marker
            :project="project.current"
            :google="google"
            :map="map"
            @drag="updateLocation"
            :removeOldMarkers="remove"
          />
        </template>
      </google-map-loader>

      <div class="flex items-center absolute top-8 left-8 overflow-auto">
        <div>
          <project-image :project="project.current" />
        </div>
        <div class="ml-7">
          <!-- <SearchInput
            :value="location"
            :debounceTime="400"
            class="bg-white w-80"
            ref="autocomplete"
            placeholder="Escribe una dirección"
          /> -->
        </div>
      </div>
    </div>

    <div slot="actionbar">
      <div class="grid grid-cols-4 gap-4 py-2">
        <div class="text-left inline-block ml-6">
          <button
            type="button"
            class="rounded-full bg-primary focus:outline-none focus:ring h-10 w-10 ml-2"
            @click="$router.back()"
          >
            <i class="tmlps-i-arrow-left text-3xl h-8 w-8 text-white"></i>
          </button>
          <div class="text-sm text-left">Regresar</div>
        </div>
        <div class="col-span-2 flex flex-col">
          <h3 class="text-center text-base font-bold">Ubicación del proyecto</h3>
          <span class="text-sm text-center">Puedes ingresar una dirección o mover el pin</span>
        </div>
        <div class="flex justify-end mr-6">
          <BaseButton class="h-10 w-50 ml-3" @click="handleConfirm">
            Confirmar ubicación
          </BaseButton>
        </div>
      </div>
    </div>
  </FullLayout>
</template>

<script>
import config from '@/config';
import { mapState } from 'vuex';
import FullLayout from '@/layouts/FullLayout';
import BaseButton from '@/components/BaseButton';

import ProjectImage from '@/components/ProjectImage';
import GoogleMapLoader from '@/components/GoogleMapLoader';
import ProjectMapMarker from '@/components/ProjectMapMarker';
export default {
  name: 'project-location',
  components: {
    FullLayout,
    BaseButton,

    ProjectImage,
    GoogleMapLoader,
    ProjectMapMarker,
  },
  data() {
    return {
      remove: 343,
      location: '',
      apiKey: config.GOOGLE_MAPS_API_KEY,
      mapOptions: {
        center: {
          lat: 0,
          lng: 0,
        },
        zoom: 17,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        zoomControl: true,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(['project']),
    project_id() {
      return this.$route.params.project_id;
    },
    spaceLimit() {
      return Math.round((this.project.current.video_storage_limit_kb / (1024 * 1024)) * 100) / 100;
    },
  },
  methods: {
    async handleConfirm() {
      const formData = new FormData();
      formData.append('name', this.project.current.name);
      formData.append('space_limit', this.spaceLimit);
      formData.append('timezone', this.project.current.timezone);
      formData.append('theme_color', this.project.current.theme_color);

      formData.append('latitude', this.mapOptions.center.lat);
      formData.append('longitude', this.mapOptions.center.lng);

      this.loading = true;

      try {
        await this.$store.dispatch('project/updateProject', {
          projectId: this.project_id,
          data: formData,
        });

        this.$store.dispatch('notification/addSuccess', 'Se confirmo correctamente la ubicación.');
        this.$router.push({ name: 'project-home', params: { project_id: this.project_id } });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
    updateLocation(marker) {
      this.mapOptions.center.lat = marker.lat;
      this.mapOptions.center.lng = marker.lng;
    },
  },
  async created() {
    this.mapOptions.center.lat = this.project.current.latitude;
    this.mapOptions.center.lng = this.project.current.longitude;
  },
};
</script>

<style lang="sass" scoped>
.viewport
  background: #242424
</style>
